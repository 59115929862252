import { h } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Init from '../routes/init';
import AddEmojiMenu from "../routes/add_emoji_menu";
import EmojiStats from "../routes/emoji_stats";
import Authorize from "../routes/authorize";

const App = () => (
	<div id="app">
		<Router>
			<Init path="/" />
			<AddEmojiMenu path="/add_emoji_menu" />
			<EmojiStats path="/emoji_stats" />
			<Authorize path="/authorize" />
		</Router>
	</div>
)

export default App;
